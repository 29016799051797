import Vue from "vue"
import Vuex from "vuex"
import createPersistedState from "vuex-persistedstate";

import SnackBar from "./modules/snackbar"
import Auth from "./modules/auth"
import Loader from "./modules/loader"
import Settings from "./modules/settings"

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  modules: {
    SnackBar,
    Auth,
    Loader,
    Settings
  },
  state: {},
  mutations: {},
  actions: {}
})

