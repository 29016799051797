import axios from "axios";

import { clearLogoutTimer } from "../../helpers/timeout.js";

export default {
  state: {
    customer: null,
    msg: [],
  },
  getters: {
    isLoggedIn: (state) => state.customer !== null,
    customerId: (state) => state.customer && state.customer.id,
    fullName: (state) => {
      if (state.customer) {
        return `${state.customer.firstname} ${state.customer.name}`
      }
    }
  },
  mutations: {
    setCustomer(state, data) {
      if (data) {
        state.customer = data;
      } else {
        state.customer = null;
      }
    },
  },
  actions: {
    login({ commit }, { username, password }) {
      return new Promise((resolve, reject) => {
        axios
          .post("/api/app/login", {
            username: username,
            password: password,
          })
          .then((res) => {
            commit("setCustomer", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    logout({ commit }) {
      return axios.post("/api/app/logout").then(() => {
        commit("setCustomer", null);
        clearLogoutTimer();
      });
    },
  },
};
