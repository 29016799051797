export default {
  home: {
    pageTitle: "Aktive Projekte",
    projects: {
      noActiveProjects: "Aktuel sind keine Projekte aktiv"
    }
  },
  login: {
    pageTitle: "Login",
    username: "Benutzername",
    form: {
      title: "Login Fenster",
      username: "Benutzer Name oder E-Mail",
      password: "Passwort",
      submit: "Einloggen",
      resetPwd: "Passwort vergessen?",
      resetemail: "Email von dem Account",
      resetInfo: "Es wird ihnen auf die Email oben einen Link geschickt mit welchen sie Ihr Passwort neu setzen k�nnen.",
      cancel: "Abrechen",
      submitreset: "Email anfordern",
    },
    status: {
      wrongCredentials: "Bitte �berpr�fen Sie Ihre Zugangsdaten.",
      serverError: "Login error, please try again later or contact support.",
    },
  },
  logout: {
    pageTitle: "Ausloggen",
    body: "Sie wurden ausgeloggt. Bitte loggen sie sich wieder ein um weiter arbeiten zu können.",
    backToLogin: "Zurück zum login",
  },
  project: {
    pageTitle: "Projekt",
    files: {
      title: "Dateien"
    }
  },
  fileUpload: {
    filefield: {
      placeholder: "Camera Bild schiessen oder Datei auswählen"
    }
  },
  profile: {
    pageTitle: "Profile",
    list: {
      firstname: "Vorname",
        name: "Name",
        lang: "Sprache",
        email: "E-Mail",
        email2: "E-Mail 2",
        birthdate: "Geburtsdatum",
        birthplace: "Geburtsort",
        familystatus: "Familienstatus",
        firstcontact: "Erster Kontakt",
    }
  }
};
