<template>
  <router-view />
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "App",

  components: {},

  data: () => ({}),

  computed: {
    ...mapGetters([]),
  },

  created() {},
};
</script>

<style lang="scss"></style>
