import Vue from "vue";
import VueRouter from "vue-router";
import Store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "dashboard",
    component: () => import("../views/home.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/login",
    name: "login",
    component: () => import("../views/login.vue"),
  },
  {
    path: "/login/reset",
    name: "reset Pwd",
    component: () => import("../views/loginreset.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/logout.vue"),
  },
  {
    path: "/project/:id",
    name: "project",
    component: () => import("../views/project.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/profile.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta && record.meta.requiresAuth)) {
    if (!Store.state.Auth.customer) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
